import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from './https.js'
import dataV from '@jiaminghi/data-view'
import '@/assets/css/common.scss';
import * as filters from './assets/js/filters.js'
 
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.use(dataV)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')