import { formatThousands } from '@/assets/js/common'

let formatNumber = function(value) {
  let val = ''
    if (value > 100000000) {
      val = `${Math.round(parseFloat(value / 100000000 * 10)) / 10}亿`
    }else if (value > 10000) {
      val = `${Math.round(parseFloat(value / 10000 * 10)) / 10}w`
    }else {
      val = formatThousands(value)
    }
  return val
}
let dotNumber = function(value) {
  let val = value && value > 10000 ? `${Math.round(parseFloat(value / 10000 * 10)) / 10}w` : formatThousands(value)
  return val
}
let dotNumber1 = function(value) {
  let val = value && value > 10000 ? `${parseFloat(value / 10000 ).toFixed(2)}w` : formatThousands(value)
  return val
}
export { formatNumber, dotNumber, dotNumber1 }