export function delCookie(name, domain) {
    var exp = new Date()
    exp.setTime(exp.getTime() - 1)
    var cval = getCookie(name)
    if (cval != null) document.cookie = name + '=' + encodeURI(cval) + ';expires=' + exp.toGMTString() + ';Domain=' + domain + ';Path=/'
}

export function getCookie(name) {
    var arr
    var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    if (arr = document.cookie.match(reg)) {
        return decodeURI(arr[2])
    } else {
        return null
    }
}

export function setCookie(name, value, domain) {
    var days = 14
    var exp = new Date()
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
    document.cookie = name + '=' + encodeURI(value) + ';expires=' + exp.toGMTString() + ';Domain=' + domain + ';Path=/'
}
/**
 * 格式化数字成带逗号的
 * @param {Number} num  要排序的对象
 * @param {Boolean} short 是否需要转换成"万"为单位
 */
export function formatThousands(num, short = false) {
    var oriNum = num;
    if (short) {
        if (oriNum > 100000000) {
            num = (num / 100000000).toFixed(0)
        } else if (oriNum > 10000 && oriNum <= 100000000) {
            num = (num / 10000).toFixed(0)
        }
    }
    var num = (num || 0).toString(),
        result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
    }
    if (num) { result = num + result; }
    if (short) {
        if (oriNum > 100000000) {
            result = result + "亿";
        } else if (oriNum > 10000 && oriNum <= 100000000) {
            result = result + "万";
        }
    }
    return result;
}

// 将时间转为标准格式： yyyy-mm-dd
export function formatTime(time) {
    var s = new Date(time);
    var M = (s.getMonth() + 1) < 10 ? '0' + (s.getMonth() + 1) : (s.getMonth() + 1);
    var D = s.getDate() < 10 ? '0' + s.getDate() : s.getDate();
    return s.getFullYear() + '-' + M + '-' + D;
}