import axios from 'axios'
import router from './router/index'
import store from './store/index'
import { delCookie, getCookie } from './assets/js/common'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.request.use(
    config => {
        const token = store.state.token || Cookies.get('am')
        if (token) {
            config.headers.token = token
        }
        return config
    }
)
axios.interceptors.response.use(
    response => {
        if (response.data.code === 403) {
            Cookies.remove('am')
                // 只有在当前路由不是登录页面才跳转
            router.currentRoute.path !== '/login' &&
                router.replace({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath }
                }).catch(err => { console.log(err) })
        } else {
            return response
        }
    },
    error => {
        if (error.response) {
            switch (error.response.code) {
                case 401:
                case 403:
                    // 401 清除token信息并跳转到登录页面
                    // delCookie('cyberin_session','.maixunbytes.com');
                    // 只有在当前路由不是登录页面才跳转
                    Cookies.remove('am')
                    router.currentRoute.path !== '/login' &&
                        router.replace({
                            path: '/login',
                            query: { redirect: router.currentRoute.path }
                        }).catch(err => { console.log(err) })
                    break
                case 500:
                    Message.error('服务器异常，请稍后再试！')
            }
        }
        return Promise.reject(error)
    }
)

export default axios