import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../https'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        userInfo: {},
        platType: localStorage.getItem('platType') ? Number(localStorage.getItem('platType')) : 0,
        screenPlatType: localStorage.getItem('screenPlatType') ? Number(localStorage.getItem('screenPlatType')) : 0
    },
    getters: {},
    mutations: {
        setToken(state, data) {
            state.token = data
        },
        setUserInfo(state, data) {
            state.userInfo = data
        },
        setPlatType(state,data){
            state.platType = data
        },
        setScreenPlatType(state,data){
            state.screenPlatType = data
        }
    },
    actions: {
        async getUserInfo(context) {
            const res = await axios({
                method: 'get',
                url: '/user/info',
            })
            context.commit('setUserInfo', res.data.data)
        }
    },
    modules: {}
})