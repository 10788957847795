import NProgress from '@/utils/nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Cookies from 'js-cookie'
const Login = () => import ( /* webpackChunkName: "login" */ '../views/login.vue')
const Main = () => import ( /* webpackChunkName: "main" */ '../layout/main.vue')

const Home = () => import ( /* webpackChunkName: "home" */ '../views/home.vue')
const List = () => import ( /* webpackChunkName: "list" */ '../views/list.vue')
const Detail = () => import ( /* webpackChunkName: "list" */ '../views/detail.vue')
const topicStatistics = () => import ( /* webpackChunkName: "topicStatistics" */ '../views/topicStatistics.vue')

// 话题详情
const topicDetail = () => import ( /* webpackChunkName: "topicDetail" */ '../views/topicDetail.vue')

// 视频/笔记内容
const content = () => import ( /* webpackChunkName: "content" */ '../views/content.vue')


// 投前诊断
const accessUrl = () => import ( /* webpackChunkName: "accessUrl" */ '../views/accessUrl.vue')
const Callback = () => import ( /* webpackChunkName: "callback" */ '../views/callback.vue')
const ReadCharts = () => import ( /* webpackChunkName: "callback" */ '../views/readCharts.vue')
const diagnose = () => import ( /* webpackChunkName: "diagnose" */ '../views/diagnose.vue')
const generalSituation = () => import ( /* webpackChunkName: "generalSituation" */ '../views/generalSituation.vue')

// 爱玛大屏
const Screen = () => import ( /* webpackChunkName: "Screen" */ '../views/screen.vue')

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Main,
        redirect: '/home',
        children: [{
                path: 'home',
                name: 'Home',
                component: Home
            },
            {
                path: 'list',
                name: 'list',
                component: List
            },
            {
                path: 'detail',
                name: 'detail',
                component: Detail
            },
            {
                path: 'topic_statistics',
                name: 'topicStatistics',
                component: topicStatistics
            },
            {
                path: 'diagnose',
                name: 'diagnose',
                component: diagnose
            },
             {
                path: 'general_situation',
                name: 'generalSituation',
                component: generalSituation
            },
            {
                path: 'topic_detail',
                name: 'topicDetail',
                component: topicDetail
            },
            {
                path: 'content',
                name: 'content',
                component: content
            }
        ]
    },
    {
        path: '/login',  // 爱玛系统登录
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Login
    },
    {
        path: '/screen_login', // 大屏登录
        name: 'screenLogin',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Login
    },
    {
        path: '/access_url',
        name: 'accessUrl',
        component: accessUrl
    },
    {
        path: '/callback',
        name: 'Callback',
        component: Callback
    },
    {
        path: '/readcharts',
        name: 'ReadCharts',
        component: ReadCharts
    },
    {
        path: '/screen',
        name: 'Screen',
        component: Screen
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: () => ({ y: 0 }), //滚动到顶端
})

router.beforeEach(async(to, from, next) => {
    NProgress.start()
    const userInfo = store.state.userInfo
    // console.log(userInfo)
    const token = store.state.token || Cookies.get('am')
    if (token) {
        if (to.path === '/login') {
            next('/')
        } else if (!Object.keys(userInfo).length) {
            await store.dispatch('getUserInfo')
            next()
        } else {
            next()
        }
    } else {
        next()
    }
})
router.afterEach(() => {
    NProgress.done()
})

// 解决点击面包屑浏览器报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalPush.call(this , location).catch(err=>err)
}

export default router