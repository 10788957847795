<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'app',
}
</script>
<style>
body{
    margin: 0;
    padding: 0;
}
#app {
  font-family: '微软雅黑, Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
}
#nprogress .bar {
  background: #D34C7B!important;
}
</style>
